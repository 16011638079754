@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
.main{

}
body{
 background-color: #192734;
 /*background-color: #2b6579;*/
}
.header{
 color: #1b959f;
 padding: 4rem;
 font-size: 8vh;
 text-shadow: 0 9px 9px rgba(56, 125, 255, 0.17);
 transition: 300ms;
}
.header:hover{
 font-size: 8.5vh;
 transition: 300ms;
}
.logo {
 display: inline-block;
 padding: 3rem 3rem 3rem 12rem;
 width: 75vh;
 transition: 400ms;
}
.logo:hover {
 width: 80vh;
 transition: 400ms;
}
.download, .wiki {
 background-color: #24c3cb;
 border-radius: 15px;
 font-size: 25px;
 width: 250px;
 height: 100px;
 alignment: right;
 position: absolute;
 right: 40vh;
 top: 40vh;
 font-family: 'Press Start 2P', cursive;
 border-color: #24c3cb;
 box-shadow: #62ede2 0.4vh 0.4vh 0.5vh 0.5vh;
 transition: 250ms;
}

.download:hover, .wiki:hover{
 transition: 250ms;
 box-shadow: #62ede2 0.2vh 0.2vh 1.3vh 1.3vh;
}

.wiki {
 top: 60vh;
}
